// src/components/ImageUpload.js
import { Button, message } from 'antd';
import Upload from 'antd/es/upload/Upload';
import React, { useRef, useState } from 'react';
import { LuHardDriveUpload } from 'react-icons/lu';
import S from "./Frame.module.css";
import { MdAddPhotoAlternate } from 'react-icons/md';

const ImageUpload = ({ onImageSelect }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const width = 320;
      const height = width / aspectRatio;
      const x = (width - 320) / 2;
      const y = (height - 320) / 2;
      canvas.width = 320;
      canvas.height = 320;
      ctx.drawImage(img, -x, -y, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');
      setSelectedImage(dataUrl);
      onImageSelect(dataUrl);
    };
    img.src = URL.createObjectURL(file);
    // if (img) {
    //   const reader = new FileReader();

    //   reader.onloadend = () => {
    //     setSelectedImage(reader.result);
    //     onImageSelect(reader.result);
    //   };
    //   reader.readAsDataURL(img);
    // }
  };

  return (
    <div style={{ display: "flex", flex: 1, width: "100%", maxWidth: 350 }}>

      <input type="file" style={{ display: "none"}} ref={fileRef} accept="image/*" onChange={handleImageChange} />
      <button onClick={() => fileRef.current.click()} className={S.fileButton}>
      <MdAddPhotoAlternate size={24} /> Selecionar Imagem
      </button>
    </div>
  );
};

export default ImageUpload;
