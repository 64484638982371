// src/App.js
import React, { useEffect, useState } from "react";
import ImageUpload from "./components/ImageUpload";
import FrameOverlay from "./components/FrameOverlay";
import { CardCandidato } from "./components/CardCandidato";
import "./App.css";

import api from "./services/api";
import { Loading } from "./components/Loading";
import { Helmet } from "react-helmet";

import frameImage from "./frame.png";
const App = () => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [dataReceived, setDataReceived] = useState(null);
  // const [frameImage, setFrameImage ]= useState("");

  async function handleGetData() {
    await api.get("/candidato/marceloneto").then(async ({ data }) => {
      localStorage.setItem("candidato", data?.slug);
      setDataReceived(data);
      // setFrameImage(data.frame);
      setLoading(false);
    });
  }

  useEffect(() => {
    !dataReceived && handleGetData();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Loading loading={loading} />
        </>
      ) : (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{dataReceived?.nome} - Meu Candidato</title>
                <link rel="canonical" href="https://meucandidato.app.br" />
                <meta
                  name="description"
                  content={dataReceived?.nome + "Meu Candidato"}
                />
            </Helmet>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardCandidato dadosCandidato={dataReceived} />
            <ImageUpload onImageSelect={setImageSrc} />
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                minHeight: 600,
                maxWidth: "370px",
                backgroundColor: dataReceived?.colorPrimary,
                alignSelf: "center",
                borderRadius: 10,
              }}
            >
              <FrameOverlay imageSrc={imageSrc} frameSrc={frameImage} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default App;
