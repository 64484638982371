// src/components/FrameOverlay.js
import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import S from "./Frame.module.css"
import { VscCloudDownload } from 'react-icons/vsc';
import api from '../services/api';

const FrameOverlay = ({ imageSrc, frameSrc }) => {
    const [ linky, setLinky ] = useState(null);
  const canvasRef = useRef();

  const handleDownload = async () => {
    const canvas = await html2canvas(canvasRef.current);
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    canvas.width = 800;
    canvas.height = 800;
    link.download = 'meu-candidato.png';
    const blob = await (await fetch(canvas.toDataURL('image/png'))).blob();
    const url = URL.createObjectURL(blob);
    setLinky(url);
    link.click(url);
    return await api.put("/candidato/apoiar", {
      slug: localStorage.getItem("candidato")
    });
  };

  return (

    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 20,
        maxWidth: 350,
        backgroundColor: "#FFF",
        border: "1px solid #EEE",
        borderRadius: 15,
        margin: 10,
        padding: 10
    }}>
      <div
        ref={canvasRef}
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '320px',
          height: '320px',
          maxHeight:'320px',
          boxSizing: "border-box",
          gap: 20,
          alignSelf: "center",

        }}
      >
        {imageSrc && <img src={imageSrc} alt="User's choice" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
        {frameSrc && (
          <img
            src={frameSrc}
            alt="Frame"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </div>
      <button
        onClick={handleDownload}
        className={S.downBtn}
        disabled={!imageSrc}

        style={{ marginTop: '10px', width: '100%', cursor: imageSrc ? "pointer": "not-allowed" }}
      >
        <VscCloudDownload size={24} />
        Baixar Imagem
      </button>
    </div>
  );
};

export default FrameOverlay;
