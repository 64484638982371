import axios from "axios";
const nodeEnv = process.env.REACT_APP_BASE_URL;
const secretWord = process.env.REACT_APP_AUTH_WORD;

const api = axios.create({
  baseURL: nodeEnv,
});

api.interceptors.request.use(async (config) => {
    config.headers["auth"] = secretWord;
  return config;
});

export default api;