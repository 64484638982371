import React from "react";
import Lottie from "lottie-react";
import Anime from "../../assets/load.json";
import Backdrop from "@mui/material/Backdrop";

export const Loading = ({ loading }) => {
  return (
    <>
      <Backdrop open={loading}  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Lottie animationData={Anime} loop={true} style={{ width: 200 }} />
      </Backdrop>
    </>
  );
};
