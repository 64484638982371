import React from 'react';
import S from "./Card.module.css";
import { IoIosPeople } from 'react-icons/io';

export const CardCandidato = ({dadosCandidato }) => {
    return (
        <div className={S.cardFrame} >
            <div  style={{ display: "flex", flexDirection: "column" }}>
                <img src={dadosCandidato?.miniatura} className={S.miniatura} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={S.title}>{dadosCandidato.nome}</span>
            <span className={S.apoios}><IoIosPeople size={24} /> {dadosCandidato.apoios} Apoiadores</span>
            </div>
        </div>
    );
}